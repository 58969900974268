import { ref, computed } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/libs/i18n'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useClientsList({ fetchAction, deleteActionName }) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  const perPage = ref(10)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref('nom')
  const isSortDirDesc = ref(false)

  const total = computed(() => (refListTable.value ? refListTable.value.filteredItems.length : 0))

  const dataMeta = computed(() => {
    let to = perPage.value * currentPage.value
    to = to > total.value ? total.value : to

    return {
      from: perPage.value * (currentPage.value - 1) + (total.value ? 1 : 0),
      to,
      of: total.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  const fetchData = () => fetchAction()
    .then(response => {
      const { data } = response
      return data
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: i18n.t('client.list.error.fetch'),
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return []
    })

  const deleteItem = itemId => {
    store
      .dispatch(deleteActionName, itemId)
      .then(() => {
        refetchData()
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('client.list.error.delete'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchData,
    perPage,
    currentPage,
    total,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refListTable,
    refetchData,
    deleteItem,

  }
}
