<template>

  <div>

    <!-- Client Selector -->
    <client-selector
      :selected-client-id.sync="selectedClientId"
    />

    <!-- Filters -->
    <sites-list-filters
      :nom-filter.sync="nomFilter"
      :site-type-filter.sync="siteTypeFilter"
      :code-filter.sync="codeFilter"
      :site-type-options="siteTypeOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50 select-cursor"
            ><div slot="no-options">
              Aucune option
            </div></v-select>
            <label>{{ $t('entries') }}</label>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <div
              v-if="$ability.can('create', 'Site')"
              class="d-flex align-items-center justify-content-end"
            >
              <b-button
                variant="primary"
                @click="onAddSiteButtonClicked()"
              >
                <span class="text-nowrap">{{ "Ajouter un site" }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchData"
        :per-page="perPage"
        :current-page="currentPage"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-filtered-text="Aucun site ne correspond à la recherche"
        :sort-desc.sync="isSortDirDesc"
        :no-provider-sorting="true"
        :no-provider-paging="true"
        :no-provider-filtering="true"
        :filter="filter"
        :filter-function="filterItem"
      >

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="$ability.can('update', 'Site')"
              :to="{ name: 'update-site', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t('action.edit') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$ability.can('delete', 'Site')"
              @click="deleteItem(data.item.id )"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ $t('action.delete') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ dataMeta.from }}-{{ dataMeta.to }} {{ $t('on') }} {{ dataMeta.of }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import router from '@/router'
import vSelect from 'vue-select'
import { watch } from '@vue/composition-api'
import useSitesList from './useSitesList'
import SitesListFilters from './SitesListFilters.vue'
import ClientSelector from './ClientSelector.vue'
import { StorageManager, FILTER_CLIENT_KEY } from '../../../utils/StorageManager'

export default {
  components: {
    SitesListFilters,

    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,

    ClientSelector,
  },
  mounted() {
    if (router.currentRoute.params.id !== null && router.currentRoute.params.id !== undefined) {
      this.selectedClientId = router.currentRoute.params.id
    } else if (StorageManager.getFilter(FILTER_CLIENT_KEY) != null) {
      this.selectedClientId = StorageManager.getFilter(FILTER_CLIENT_KEY)
    }
  },
  setup() {
    const {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      deleteItem,

      selectedClientId,

      filter,
      filterItem,
      nomFilter,
      siteTypeFilter,
      codeFilter,

    } = useSitesList()

    const siteTypeOptions = ['destination', 'provenance']

    const onAddSiteButtonClicked = () => {
      router.push({ name: 'add-site', params: { clientId: selectedClientId.value } })
    }

    watch(selectedClientId, val => {
      StorageManager.setFilter(FILTER_CLIENT_KEY, val)
    })

    return {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      deleteItem,

      siteTypeOptions,

      // client selector
      selectedClientId,

      // Filter
      filter,
      filterItem,
      nomFilter,
      siteTypeFilter,
      codeFilter,

      // event
      onAddSiteButtonClicked,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
