import useList from '@/views/hooks/useList'
import store from '@/store'
import { ref, computed, watch } from '@vue/composition-api'
import ability from '@/libs/acl/ability'

export default function useClientsList() {
  const selectedClientId = ref(null)

  // use List
  const list = useList({
    fetchAction: () => {
      if (!selectedClientId.value) {
        return []
      }
      return store.dispatch('site/fetchSites', selectedClientId.value)
    },
    deleteActionName: 'site/deleteSite',
  })

  watch([selectedClientId], selectedClientIdValue => {
    if (selectedClientIdValue !== null) {
      list.refetchData()
    }
  })

  // DATA
  const tableColumns = [
    { label: 'nom', key: 'nom', sortable: true },
    { label: 'type', key: 'siteType', sortable: true },
    { label: 'code', key: 'code', sortable: true },
  ]

  if (ability.can('update', 'Site') || ability.can('delete', 'Site')) {
    tableColumns.push({ label: 'Actions', key: 'actions' })
  }

  const nomFilter = ref(null)
  const siteTypeFilter = ref(null)
  const codeFilter = ref(null)

  // COMPUTED
  const filter = computed(() => [
    nomFilter.value,
    siteTypeFilter.value,
    codeFilter.value,
  ])

  // METHODS
  const fitlerItemByProperty = (itemProperty, filterRef) => {
    if (!filterRef.value) {
      return true
    }

    const regexp = new RegExp(filterRef.value, 'i')
    return regexp.test(itemProperty)
  }

  const filterItem = item => {
    const matchNom = fitlerItemByProperty(item.nom, nomFilter)
    const matchSiteType = fitlerItemByProperty(item.siteType, siteTypeFilter)
    const matchCode = fitlerItemByProperty(item.code, codeFilter)

    return matchNom && matchSiteType && matchCode
  }

  return {
    tableColumns,

    // list
    ...list,

    selectedClientId,

    // Filters
    filter,
    filterItem,
    nomFilter,
    siteTypeFilter,
    codeFilter,
  }
}
